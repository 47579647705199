
import dayjs from "dayjs"
import React from "react";

class Time extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
      timer: null
    };
  }
  componentDidMount(){
    const timer = setInterval(() => {
      const date = dayjs(new Date())
      const dateDay = date.format('HH:mm:ss');
      const dateYear = date.format('YYYY-MM-DD');
      const dateWeek = date.format(this.state.weekday[date.day()]);
      this.setState({
        dateDay,
        dateYear,
        dateWeek
      })

    }, 1000)
    this.setState({
      timer
    })
  }
  render() {
    const { dateDay, dateYear, dateWeek } = this.state
    return (
      <div className='confirm-mask'>
        &nbsp;{dateYear}&nbsp;{dateDay}
      </div>
    );
  }
}

export default Time;