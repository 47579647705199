import React from 'react';
import './index.css';

let codeArr = []
let codeStr = ''

class VCode extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ...this.initState(),
      Vcode: '',
    }
  }

  initState(){
    return {
      data: this.getRandom(109,48,4),
      rotate: this.getRandom(75,-75,4),
      fz: this.getRandom(14,26,10),
      color: [this.getRandom(100,255,3),this.getRandom(100,255,4),this.getRandom(100,255,3),this.getRandom(100,255,3)]
    }
  }

  getRandom(max, min, num) {
    const asciiNum = ~~(Math.random()*(max-min+1)+min)
    if(!Boolean(num)){
      return asciiNum
    }
    const arr = []
    for(let i = 0; i < num; i++){
      arr.push(this.getRandom(max, min))
    }
    return arr
  }

  fromCharCode(v) {
    let val = String.fromCharCode(v)
    codeArr.push(val)
    if(codeArr.length === 4) {
      codeStr = codeArr.toString().replace(/\,/g, '')
      codeArr = []
    } 
    return val
  }

  codeChange(e) {
    let val = e.target.value

    this.setState({Vcode: val})
    if(val.length === 4 || val.length > 4) {
      if (val.toLocaleLowerCase() !== codeStr.toLocaleLowerCase()) {
        this.props.isErrCodeChange(false)
      } else {
        this.props.isErrCodeChange(true)
      }
    }
    console.log(val)
  }

  render() {
    const { rotate, color, fz } = this.state
    return (
      <div className='center-flex'>
        <div className={`my-input-style flex-1 ${!this.props.isErrCode ? 'err-style': ''}`}>
          <span className='input-icon1'></span>
          <input placeholder='请输入右侧校验码' onChange={(e) => this.codeChange(e)}></input>
        </div>
        <div className='vcodewrap' >
          {/* <canvas id="bgi" width="40" height="20"></canvas> */}
          <div className='itemStrBox'>
            {this.state.data.map((v,i) => 
              <div 
                key={i}
                className='itemStr'
                style={{
                  // transform:`rotate(${rotate[i]}deg)`,
                  fontSize: `${fz[i]}px`,
                  // color: `rgb(${color[i].toString()})`
                  color: '#fff'
                }}
                onMouseEnter={() => {this.setState({refresh:true});}}
              >
                {this.fromCharCode(v > 57 && v < 84 ? v + 7 : ( v < 57 ? v : v + 13 ))}
              </div>  
            )}
          </div>
          
        {
          this.state.refresh
          ? <div 
              className='mask'
              onClick={() => {
                this.setState({...this.initState(),refresh: false})
              }}
              onMouseLeave={() => {this.setState({refresh: false})}}
            > 看不清？点击刷新  
            </div> 
          : null}
        </div>
      </div>
      
    )
  }


}
export default VCode;