import React from 'react';
import './index.css';
import { BorderBox8 } from '@jiaminghi/data-view-react'


class BorderBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className='BorderBox-body'>
        <div className="BorderBox-header">
          { this.props.title }
        </div>
        <div className='BorderBox-content'>
          {/* <BorderBox8> */}
            {/* <div className='BorderBox-content'> */}
              {this.props.container}
            {/* </div> */}
            
          {/* </BorderBox8> */}
        </div>
        
      </div>
    );
  }
}

export default BorderBox;