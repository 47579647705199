import React, { useState, useRef } from 'react';
import './index.css';
import { BorderBox13 } from '@jiaminghi/data-view-react'
import { login } from '../../services/api';
import { encrypt } from "../../utils/JSEncrypt"
import { useNavigate } from "react-router-dom";
import VCode from "../../components/VCode";
import Toast from 'light-toast';

const FORMAT = 'YYYY/MM/DD HH:mm:SS';


const Login = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [isShowText, setIsShowText] = useState(false)
  const [isErrCode, setIsErrCode] = useState(true) // 验证码
  const navigate = useNavigate();
  const usernameRef = useRef()
  const passwordRef = useRef()

  const loginChange = () => {
    if (!username) {
      Toast.info('请输入用户名称', 1500);
      return false
    }
    if (!password) { 
      Toast.info('请输入登录密码', 1500)
      return false
    }
    if (!isErrCode) {
      Toast.info('请输入正确的验证码', 1500)
      return false
    }
    let data = {
      username: username,
      password: encrypt(password), // 字段加密
    }
    login(data).then(res => {
      if (res.token) {
        navigate("/home");
      } else {
        Toast.fail(res.message, 1500)
      }
    })
  }

  const isErrCodeChange = (val) => {
    setIsErrCode(val)
  }
  
    
    return (
      <div className='login'>
        <div className='login_bg1'></div>
        <div className='login_bg2'></div>
        <div className='login-box'>
          {/* <BorderBox13> */}
            <div className='login-content'>
              <p className='login-title'>
                {/* 用户登录&nbsp;&nbsp;
                <span>USER LOGIN</span> */}
              </p>
              <div className='my-input'>
                <span className='input-icon input-icon-1'></span>
                <input ref={usernameRef} placeholder='请输入用户名称' onChange={(e) => setUsername(e.target.value)}></input>
              </div>
              <div className='my-input'>
                <span className='input-icon input-icon-2'></span>
                <input ref={passwordRef} placeholder='请输入登录密码' onChange={(e) => setPassword(e.target.value)}></input>
              </div>
              <VCode isErrCodeChange ={isErrCodeChange} isErrCode={isErrCode}/>
              <div className='center-between'>
                <div className='center-flex'>
                  <input type='checkbox' class="toggle"></input>
                  <span>记住密码</span>
                </div>
                <div onMouseEnter={() => setIsShowText(true)} onMouseLeave={() => setIsShowText(false)}>忘记密码?</div>
                {isShowText && <div className='isShowMask'>
                  请联系管理员获取登录密码<div className='triangle-down'></div>
                  </div>}
              </div>
              
              <div className='login-btn' onClick={loginChange}>登录</div>
            </div>
          {/* </BorderBox13> */}
        </div>
      </div>
    )
}

export default Login;
