import axios from "axios";
import Cookies from "js-cookie"

// export const root = process.env.REACT_APP_API;

// json 请求头
export const oXHRHeaders = {
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
  },
};

// url序列化请求头
export const oXHRHeadrsUrlencoded = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

// formData表单请求头
export const oXHRHeadrsFormData = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

// qs序列化url请求参数
export const QS = require("qs");

/**
 * fetch Get请求
 * @param {String} url 请求地址
 * @param {Obejct} params
 * @return {Promise}
 */
export function FetchGet(url, params = {}, headers = {}) {
  return new Promise((resolve, reject) => {
    axios
      .get(
        url,
        {
          params,
        },
        headers
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

/**
 * fetch Post请求
 * @param {String} url 请求地址
 * @param {Obejct} params
 * @return {Promise}
 */
export function FetchPost(url, data = {}, headers = {}) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, data, headers)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

/**
 * fetch Post请求
 * @param {String} url 请求地址
 * @param {Obejct} params
 * @return {Promise}
 */
export function postForm(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.post(url, data).then(response => {
      resolve(response.data);
    }).catch(err => {
      reject(err);
    })
  })
}

// export const Http = axios.create({
//   timeout: 5000,
//   baseURL: process.env.VUE_APP_BASE_API,
//   headers: { "Content-Type": "application/json" },
// })

// // 请求拦截器
// Http.interceptors.request.use(
//   (config) => {
//     let isAuthenticated = Cookies.get("isAuthenticated") || false
//     isAuthenticated ? (config.headers["Authorization"] = isAuthenticated) : ""
//     return config
//   },
//   (error) => Promise.reject(error)
// )

// // 响应拦截器
// Http.interceptors.response.use(
//   (response) => {
//     // do something...
//     return response
//   },
//   (error) => Promise.reject(error)
// )