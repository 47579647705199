import React, { Component } from 'react'
import echarts from 'echarts'
import 'echarts/map/js/china'
import geoJson from 'echarts/map/json/china.json'
import { noticeLists, myChartOption, diseaseOption, newNumberOption, oldManSourseOption } from '../../geo'
import { Decoration1 ,BorderBox8,ScrollBoard, BorderBox12, FullScreenContainer } from '@jiaminghi/data-view-react'
import './index.css'
import { getAnalysis, 
  elderlyDiseasesStatistics,
  abnormalIndexStatistics,
  organizationInformationStatistics,
  screenStatistics } from '../../services/api';
import timeIcon from '../../assets/images/icon_clock.svg'
import Time from '../../components/time'
import BorderBox from '../../components/borderBox'
import NumberShow from '../../components/numberShow'
import ShanghaiMap from '../../components/shanghaiMap'
import dayjs from "dayjs"

class Home extends React.Component{
// className App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      analysisInfo: {},
      myChart1: null,
      myChart2: null,
      myChart5: null,
      myChart7: null,
      myChart8: null,
      myChart9: null,
      myChart10: null,
      myChart11: null,
      anomalyIndicators: {}, // 异常指标统计
      organizationInfo: {}, //机构信息
      myChartOption1: JSON.parse(JSON.stringify(myChartOption)), // 老人年龄
      myChartOption2: JSON.parse(JSON.stringify(myChartOption)), // 老人等级
      myChartOption3: JSON.parse(JSON.stringify(myChartOption)), // 老人性别
      myChartOption4: JSON.parse(JSON.stringify(myChartOption)), // 老人来源
      myChartOption5: JSON.parse(JSON.stringify(oldManSourseOption)), // 老人来源
      diseaseOption: JSON.parse(JSON.stringify(diseaseOption)), // 老人疾病情况 
      newNumberOption: JSON.parse(JSON.stringify(newNumberOption)), // 新增人数分析
      alarmInfo: {}, // 告警信息统计
      tableData:{
        header: ['时间', '床位号', '事件'],
        data: [],
        columnWidth: [50],
        align: ['center'],
        headerBGC: 'transparent',
        oddRowBGC: '#04274F',
        evenRowBGC: '#041E3F',
      },
      tableData2:{
        header: ['服务名称', '服务次数', '满意度'],
        data: [
          ['进食', '1', 'icon'],
        ],
        columnWidth: [50],
        align: ['center'],
        headerBGC: 'transparent',
        oddRowBGC: '#04274F',
        evenRowBGC: '#041E3F',
        index: true,
        indexHeader: '名次'
      },
      noticeList:noticeLists
    }
  }
  componentDidMount() {
    this.initalECharts()
    this.initalECharts9()
    this.getInfo() // 接口调用
    const that = this
    window.onresize = function() {
      that.state.myChart1.resize()
      that.state.myChart2.resize()
      that.state.myChart5.resize()
      that.state.myChart7.resize()
      that.state.myChart8.resize()
      that.state.myChart9.resize()
      that.state.myChart10.resize()
      that.state.myChart11.resize()
    }
  }
  initalECharts4() {
    this.setState(
      { myChart5: echarts.init(document.getElementById('mainMap2')) },
      () => {
        this.state.myChart5.setOption(this.state.newNumberOption)
      }
    )
  }
  initalECharts1() {
    this.setState(
      { myChart2: echarts.init(document.getElementById('provinceMap')) },
      () => {
        this.state.myChart2.setOption(this.state.myChartOption1)
      }
    )
  }
  initalECharts6() {
    this.setState(
      { myChart7: echarts.init(document.getElementById('oldLevelMap')) },
      () => {
        this.state.myChart7.setOption(this.state.myChartOption2)
      }
    )
  }
  initalECharts7() {
    this.setState(
      { myChart8: echarts.init(document.getElementById('oldGenderMap')) },
      () => {
        this.state.myChart8.setOption(this.state.myChartOption3)
      }
    )
  }
  initalECharts8() {
    this.setState(
      { myChart9: echarts.init(document.getElementById('oldSourceMap')) },
      () => {
        this.state.myChart9.setOption(this.state.myChartOption4)
      }
    )
  }
  initalECharts9() {
    this.setState(
      { myChart10: echarts.init(document.getElementById('oldHealthMap')) },
      () => {
        this.state.myChart10.setOption({
          color: ['#5C8FF9', '#56CEA1', '#596B8E', '#EAB41C', '#695AEE'],
          tooltip: {
            trigger: 'item',
            formatter: '{b}: {c} ({d}%)'
          },
          legend: {
            top: 'bottom',
            data: ['动脉粥样硬化', '骨质疏松', '慢阻肺', '高血压', '糖尿病'],
            textStyle: {
              fontSize: 12,
              color: '#ffffff'
            },
            icon: 'circle',
            itemWidth: 10, // 设置宽度
            itemHeight: 10, // 设置高度
            itemGap: 10 // 设置间距
          },
          series: [
            {
              type: 'pie',
              radius: ['50%', '70%'],
              center: ['50%', '45%'],
              data: [
                { value: 10, name: '动脉粥样硬化' },
                { value: 310, name: '骨质疏松' },
                { value: 234, name: '慢阻肺' },
                { value: 234, name: '高血压' },
                { value: 234, name: '糖尿病' },
              ]
            }
          ]
        })
      }
    )
  }
  initalECharts10() {
    this.setState(
      { myChart11: echarts.init(document.getElementById('oldDiseaseMap')) },
      () => {
        this.state.myChart11.setOption(this.state.diseaseOption)
      }
    )
  }
  initalECharts() {
    this.setState(
      { myChart1: echarts.init(document.getElementById('oldMan')) },
      () => {
        this.state.myChart1.setOption(this.state.myChartOption5)
      }
    )
  }
  // 老人年龄接口
  getInfo() {
    getAnalysis().then(res => {
      const { myChartOption1, myChartOption2, myChartOption3, myChartOption4, newNumberOption  } = this.state
      const data = res.body
      const cumulativeNumbe = data.cumulativeNumberOfElderlyForScreenVo?.dailyInNumber
      const oldAge = data.ageDistributionOfTheElderlyForScreenVo
      const oldLevel = data.nursingGradeOfTheElderlyForScreenVo?.nursingGradeMap
      const oldGender = data.genderOfTheElderlyForScreenVo
      const oldSoure = data.marketingChannelOfTheElderlyForScreenVo?.marketingChannelMap
      Object.keys(cumulativeNumbe).map(k => {
        newNumberOption.xAxis.data.push(dayjs(k).format('MM/DD'))
        newNumberOption.series[0].data.push(cumulativeNumbe[k])
      })
      Object.keys(oldLevel).map(k => {
        myChartOption2.legend.data.push(k)
        myChartOption2.series[0].data.push({value: oldLevel[k], name: k})
      })
      Object.keys(oldSoure).map(k => {
        myChartOption4.legend.data.push(k)
        myChartOption4.series[0].data.push({value: oldSoure[k], name: k})
      })
      let oldAgeList = [
        { value: oldAge.above90, name: '90岁以上' },
        { value: oldAge.between80And89, name: '80-89岁' },
        { value: oldAge.between70And79, name: '70-79岁' },
        { value: oldAge.between60And69, name: '60-69岁' },
        { value: oldAge.below60, name: '60岁以下' }
      ]
      myChartOption1.legend.data = ['90岁以上', '80-89岁', '70-79岁', '60-69岁', '60岁以下']
      myChartOption1.series[0].data = oldAgeList
      let oldGenderList = [
        { value: oldGender.male, name: '男' },
        { value: oldGender.female, name: '女' }
      ]
      myChartOption3.legend.data = ['男', '女']
      myChartOption3.series[0].data = oldGenderList
      this.setState({ 
        analysisInfo: data,
        myChartOption1: myChartOption1,
        myChartOption2: myChartOption2,
        myChartOption3: myChartOption3,
        myChartOption4: myChartOption4,
        newNumberOption: newNumberOption,
      })
      this.initalECharts1()
      this.initalECharts4()
      this.initalECharts6()
      this.initalECharts7()
      this.initalECharts8()
    })
    elderlyDiseasesStatistics().then(res => {
      if (res.code == '200') {
        const { diseaseOption } = this.state
        let data = res.body
        Object.keys(data).map(k => {
          diseaseOption.xAxis.data.push(k)
          diseaseOption.series[0].data.push(data[k])
        })
        this.setState({
          diseaseOption
        })
        this.initalECharts10()
      }
    })
    abnormalIndexStatistics().then(res => {
      if (res.code == '200') {
        this.setState({ anomalyIndicators: res.body })
      }
      console.log(res)
    })
    // 机构信息
    organizationInformationStatistics().then(res => {
      if (res.code == '200') {
        this.setState({ organizationInfo: res.body })
      }
    })
    screenStatistics().then(res => {
      if (res.code == '200') {
        const { tableData } = this.state
        let currentDayAlarmEvents = res.body.currentDayAlarmEvents
        let objItem = []
        currentDayAlarmEvents.map(item => {
          objItem = [item.alarmTime, item.bedDesc, item.alarmDesc] 
          tableData.data.push(objItem)
        })

        this.setState({ 
          alarmInfo: res.body,
          tableData 
        })
      }
    })
  }
  render() { 
    const { tableData, tableData2, noticeList, analysisInfo, anomalyIndicators, organizationInfo, alarmInfo} = this.state
    // 通知
    const noticeSet = (
      <div className="xpanel">
        <div className="fill-h">
          关于集团公司安全部组织第三季度全集团所有机构部门组织消防隐患排查的通知，时间、地点各机构部门根据实际情况自行安排！
          {/* 无 */}

          {/* <ScrollBoard config={noticeList}/> */}
        </div>
      </div>
    )
    // 老人年龄
    const oldAgeSet = (
        <div className="xpanel">
          <div className="fill-h" id="provinceMap"></div>
        </div>
    )
    // 老人等级
    const oldLevelSet = (
        <div className="xpanel">
          <div className="fill-h" id="oldLevelMap"></div>
        </div>
    )
    // 老人性别
    const oldGenderSet = (
        <div className="xpanel">
          <div className="fill-h" id="oldGenderMap"></div>
        </div>
    )
    // 老人来源渠道
    const oldSourceSet = (
        <div className="xpanel">
          <div className="fill-h" id="oldSourceMap"></div>
        </div>
    )
    // 老人健康风险
    const oldHealthSet = (
        <div className="xpanel">
          <div className="fill-h" id="oldHealthMap"></div>
        </div>
    )
    // 老人疾病情况
    const oldDiseaseSet = (
        <div className="xpanel">
          <div className="fill-h" id="oldDiseaseMap"></div>
        </div>
    )
    // 老人疾病情况
    const numberAnalyis = (
      <React.Fragment>
      <div className="xpanel">
        <div className='flex-between header-box'>
          <div><span className='oldNumber'>{analysisInfo.cumulativeNumberOfElderlyForScreenVo?.monthlyInElderLyNumber || '-'}</span><br/>截止2021/11/12</div>
          <div><span className='oldNumber'>{analysisInfo.cumulativeNumberOfElderlyForScreenVo?.yyLastIntervalRatio || '-'}</span><br/>同比去年</div>
          <div><span className='oldNumber'>{analysisInfo.cumulativeNumberOfElderlyForScreenVo?.mmLastIntervalRatio || '-'}</span><br/>环比上周</div>
        </div>
        <div className="fill-h-80" id="mainMap2"></div>
      </div>
      </React.Fragment>
    )
    const indicators = (
        <NumberShow num={5} info={[
          {text: '血压异常人数', number: anomalyIndicators.abnormalBloodPressureNumber||0}, 
          {text: '血糖异常人数', number: anomalyIndicators.abnormalBloodSugarNumber|| 0}, 
          {text: '血脂异常人数', number: anomalyIndicators.abnormalBloodFatNumber|| 0}, 
          {text: '心电异常人数', number: 0}, 
          {text: '尿酸异常人数', number: anomalyIndicators.abnormalUricAcidNumber|| 0}]} />
    )
    const institutionsInfo = (
      <React.Fragment>
        <NumberShow limit={2} num={5} info={[
          {text: '户型数', number: organizationInfo.roomNumber||0}, 
          {text: '入住率', number: organizationInfo.checkInRoomProportion|| 0}, 
          {text: '床位数', number: organizationInfo.bedNumber|| 0}, 
          {text: '医护人数', number: organizationInfo.medicalCareNumber|| 0}, 
          {text: '后勤人数', number: organizationInfo.logisticsNumber|| 0}]} />
        <NumberShow num={5} info={[
          {text: '运营销售人数', number: organizationInfo.operationalSalesNumber|| 0}, 
          {text: '设备数', number: 0}, 
          {text: '故障率', number: 0}, 
          {text: '故障处理数', number: 0}, 
          {text: '平均处理时长', number: 0}]} />
      </React.Fragment>
    )
    // 告警信息统计
    const alarmInfoS = (
      <React.Fragment>
        <NumberShow limit={2} num={4} info={[
          {text: '呼叫次数', number: alarmInfo.callTotalQty|| 0}, 
          {text: '呼叫响应平均分钟', number: alarmInfo.avgCallTime || 0}, 
          {text: '跌倒次数统计', number: alarmInfo.tumbleTotalQty || 0}, 
          {text: '卫生间平均逗留', number: 0}]}/>
        <NumberShow info={[
          {text: '呼吸暂停总次数', number: alarmInfo.apneaTotalQty || 0}, 
          {text: '心跳暂停总次数', number: alarmInfo.heartbeatPausedTotalQty || 0}, 
          {text: '平均离床', number: alarmInfo.avgLeaveBedTime|| 0}, 
          {text: '设备数', number: alarmInfo.deviceQty || 0}]} />
      </React.Fragment>
    )
    const alarmTips = (
      <div className="xpanel">
        <div className="fill-h" id="worldMap">
        <ScrollBoard config={tableData}  />
        </div>
      </div>
      
    )
    const satisfaction = (
      <div className="xpanel">
        <div className="fill-h" id="worldMap">
        <ScrollBoard config={tableData2}  />
        </div>
      </div>
    )
    return ( 
      <FullScreenContainer>
        <div className="wrapper_grid_box">
          <div className="header">
            <header className="header_main">
              <div className="header_main_icon">
                <img src={timeIcon} alt="" />
                <Time />
              </div>
              <div className='between-item'>
                <div className="left_bg"></div>
                <div className="right_bg"></div>
                <h3>智慧养老机构数据监控平台</h3>
              </div>
            </header>
          </div>
          <div className="div2">
          <div className="">
            <BorderBox title="通知公告" container={noticeSet}></BorderBox>
          </div>
            {/* <BorderBox title="通知公告" container={noticeSet}></BorderBox> */}
          </div>
          <div className="div3">
            {/* <BorderBox12>  */}
              <NumberShow info={[
                {text: '老人总数', number: analysisInfo.elderlyInformationSummaryForScreenVo?.totalElderlyNumber || 0}, 
                {text: '试入住老人数', number: 0}, 
                {text: '离院/外出老人数', number: (analysisInfo.elderlyInformationSummaryForScreenVo?.outElderlyNumber || 0) +'/0' }, 
                {text: '无事故运营天数', number: analysisInfo.elderlyInformationSummaryForScreenVo?.accidentFreeOperationDays || 0}]} />
            {/* </BorderBox12> */}
          </div>
          <div className="div4"> 
            <BorderBox title="异常指标统计" container={indicators}></BorderBox>
          </div>
          <div className="div5">
            <BorderBox title="老人年龄" container={oldAgeSet}></BorderBox>
          </div>
          <div className="div6">
            <BorderBox title="老人等级" container={oldLevelSet}></BorderBox>
          </div>
          <div className="div7">
            {/* <BorderBox12> */}
              <div className='mapSourse'>
                <div className='map-title'>老人来源</div>
                <div className='oldmap-center'>
                  <div className="xpanel flex-1">
                      <div className="fill-h" id="oldMan"></div>
                  </div>
                  <div className='flex-1'>
                    <ShanghaiMap />
                  </div>
                </div>
                
                  
              </div>
              
            {/* </BorderBox12> */}
          </div>
          <div className="div8">
            <BorderBox title="机构信息统计" container={institutionsInfo}></BorderBox>
          </div>
          <div className="div9">
            <BorderBox title="老人性别" container={oldGenderSet}></BorderBox>
          </div>
          <div className="div10">
            <BorderBox title="老人来源渠道" container={oldSourceSet}></BorderBox>
          </div>
          <div className="div11">
            <BorderBox title="告警信息统计" container={alarmInfoS}></BorderBox>
          </div>
          <div className="div12">
            <BorderBox title="老人健康风险评估" container={oldHealthSet}></BorderBox>
          </div>
          <div className="div13">
            <BorderBox title="老人疾病情况" container={oldDiseaseSet}></BorderBox>
          </div>
          <div className="div14">
            <BorderBox BorderBox title="新增人数分析" container={numberAnalyis}></BorderBox>
          </div>
          <div className="div15">
            <BorderBox title="报警提示" container={alarmTips}></BorderBox>
          </div>
          <div className="div16">
            <BorderBox title="服务分类满意度" container={satisfaction}></BorderBox>
          </div>
        </div>
      </FullScreenContainer>
     );
  }
}
 
export default Home