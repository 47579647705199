import React from 'react';
import './index.css';
class NumberShow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const {info, limit = 1, num = 4} = this.props
    return (
      <div className={`numberShow-box ${limit === 2 ? 'padding2' : 'padding1'}`}>
        {info.map((item, index) => {
          return (
            <div key={index} className={num === 5 ? 'flex_5' : 'flex_4'}>
              {item.text}
              <br/>
              <span className='numberShow-num'>{item.number}</span>
            </div>
          )
        })}
        
      </div>
    );
  }
}

export default NumberShow;