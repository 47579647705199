import { FetchPost, FetchGet } from './http';

export const getAnalysis = (params) =>
  FetchGet("http://api.dev.ihexiao.cn/api/screen/elderlyInformationAnalysis", params);

export const elderlyDiseasesStatistics = (params) =>
  FetchGet("http://api.dev.ihexiao.cn/api/screen/elderlyDiseasesStatistics", params);

export const abnormalIndexStatistics = (params) =>
  FetchGet("http://api.dev.ihexiao.cn/api/screen/abnormalIndexStatistics", params);

export const organizationInformationStatistics = (params) =>
  FetchGet("http://api.dev.ihexiao.cn/api/screen/organizationInformationStatistics", params);

export const screenStatistics = (params) =>
  FetchPost("http://api.dev.ihexiao.cn/api/perception/screenStatistics/query", params);
  // 登录
export const login = (params) =>
  FetchPost("http://api.dev.ihexiao.cn/auth/login", params);