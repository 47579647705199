// import React from 'react';
// import ReactDOM from 'react-dom';
// import App from './App';

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

import React from 'react';
import ReactDOM from 'react-dom';
// import { AppContainer } from 'react-hot-loader';
import { BrowserRouter } from 'react-router-dom';
import Router from './router';
import './App.css';
import './index.css';
import './utils/flexible';


function render(Component) {
  ReactDOM.render(
    // <AppContainer>
      <BrowserRouter>
        <Component />
      </BrowserRouter>,
    // </AppContainer>,
    document.getElementById('root')
  );
}

/* 初始化 */
render(Router);

// /* 热更新 */
// if (module.hot) {
//   module.hot.accept('./router/index.js', () => {
//     render(Router);
//   });
// }

