import React from 'react';
import './index.css';
import geoJson from './putuo.json'//普陀区地图
import echarts from 'echarts'
class shanghaiMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      myChart: null
    };
  }
  componentDidMount() {
    const that = this
    this.setCharts()
    window.onresize = function() {
      that.state.myChart.resize()
    }
  }
  setCharts = async (isReload = false) => {
    this.state.myChart = echarts.init(document.getElementById("shanghai-chart"))
    echarts.registerMap('shanghai', geoJson)
    this.state.myChart.hideLoading()
    let data = [
      {
        name: '桃浦镇',
        value: 9,
      },
      {
        name: '长征镇',
        value: 32,
      },
      {
        name: '真如镇街道',
        value: 25,
      },
      {
        name: '甘泉路街道',
        value: 25,
      },
      {
        name: '石泉路街道',
        value: 32,
      },
      {
        name: '长风新村街道',
        value: 9,
      },
      {
        name: '宜川路街道',
        value: 25,
      },
      {
        name: '长寿路街道',
        value: 95,
      },
      {
        name: '曹杨新村街道',
        value: 32,
      },
      {
        name: '万里街道',
        value: 92,
      }
    ]
    const getColor = (value) => {
      if (value <= 10) {
        return '#4AA9FF'
      } else if (value > 10 && value <= 20) {
        return '#1280EC'
      } else if (value > 20 && value <= 30) {
        return '#1480ED'
      } else if (value > 30 && value <= 40) {
        return '#92D0FF'
      } else if (value > 40) {
        return '#0050B4'
      }
    }
    const regionsList = data.map((v) => {
      return {
        name: v.name,
        emphasis: {
          label: {
            color: '#fff',
            show: true,
          },
        },
        itemStyle: {
          label: {
            show: true,
            color: '#fff',
          },
          areaColor: getColor(v.value),
          emphasis: {
            areaColor: '#1890FF',//鼠标放在地图上时的颜色
          },
        },
      }
    })
  
    // 绘制图表
    this.state.myChart.setOption({
      geo: {
        show: true,
        map: 'shanghai',
        roam: false,
        label: {
          show: true,
          color: '#fff'//地图上文字的颜色
        },
        // itemStyle: {
        //   normal: {
        //     borderColor: '#4D5356',//各模块边界线
        //     borderWidth: 2,
        //     shadowColor: 'rgba (63,218,255,0.3)',
        //     shadowBlur: 20,
        //     show: true
        //   },
        // },
        regions: regionsList,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
      }
    }, isReload)
  }
  render() {
    // const {info, limit = 1} = this.props
    return (
      <div id="shanghai-chart"
       style={{width: '100%', height: '100%'}}></div>
    );
  }
}

export default shanghaiMap;